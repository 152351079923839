<template>
    <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
      <div class="container-fluid">
        <router-link to="/" class="navbar-brand"><img src="../assets/logo.png" /></router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" 
                data-bs-target="#navbar04" aria-controls="navbar04" 
                aria-expanded="false" aria-label="Menü ki/be">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbar04">
          <ul v-if="selectedLang === 'hu'" class="navbar-nav me-auto mb-2 mb-md-0">
            <li class="nav-item">
              <router-link to="/" class="nav-link" exact>Főoldal</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/services" class="nav-link" exact>Szolgáltatásaink</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/references" class="nav-link" exact>Referenciák</router-link>
            </li>
            <li class="nav-item">
              <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Pénzügyi hírek</a>
            </li>
            <li class="nav-item">
              <router-link to="/contact" class="nav-link" exact>Kapcsolat</router-link>
            </li>
          </ul>
          <ul v-if="selectedLang === 'en'" class="navbar-nav me-auto mb-2 mb-md-0">
            <li class="nav-item">
              <router-link to="/" class="nav-link" exact>Main</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/references" class="nav-link" exact>References</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/contact" class="nav-link" exact>Contact</router-link>
            </li>
          </ul>
          <form>
            <select v-model="selectedLang" @change="this.$emit('change',selectedLang)">
              <option
                v-for="(lang, i) in langs"
                :key="`lang-${i}`"
                :value="lang">
                  {{ lang }}
              </option>
            </select>
          </form>
        </div>
      </div>
    </nav>
</template>

<script>
export default{
  name: 'langSelector',
  data() {
    return { 
      langs: ['hu','en'],
      selectedLang: 'hu' }
  }
}
</script>
