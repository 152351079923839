<template>
        <div class="row row-cols-1 row-cols-12 trns">
            <h2 class="display-5 fw-bold">References</h2>
            <p>Our best reference would be if we had the chance to name our clients. Due to the business and tax secrecy obligations we 
              can only indicate here the projects.</p>
            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
              <div class="col d-flex align-items-start">
                <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-auchan.png"></div>
                AUCHAN Logistic Centre, Üllő</div>
              <div class="col d-flex align-items-start"><div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-obuda.png"></div>
              Sport Complex investment, Budapest, district III.</div>
              <div class="col d-flex align-items-start">
              <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-budakalasz.png"></div>
              Herb processing plant, Budakalász</div>
              <div class="col d-flex align-items-start">
              <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-renault.png"></div>
              Renault-Nissan warehouse and logistic investment, Győr</div>

              <div class="col d-flex align-items-start">
                <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-continental.png"></div>
                Continental Logistics Center, Mosonmagyaróvár</div>
              <div class="col d-flex align-items-start"><div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-ikarus.png"></div>
              IKARUS industrial park purchase and resale</div>
              <div class="col d-flex align-items-start">
              <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-dhl.png"></div>
              DHL Logistic Centre (Best Warehouse and Logistic Development of the year, 2007)</div>
              <div class="col d-flex align-items-start">
              <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-nolato.png"></div>
              Industrial park purchase and resale, Jászberény</div>

              <div class="col d-flex align-items-start">
                <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-logistics.png"></div>
                Logistic Centre, Budapest, district XVII.</div>
              <div class="col d-flex align-items-start"><div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-logistics.png"></div>
              Logistic Centre purchase, Nyíregyháza</div>
              <div class="col d-flex align-items-start">
              <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-renault.png"></div>
              Renault-Dacia Logistics Centre, Pitesti, Romania</div>
              <div class="col d-flex align-items-start">
              <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-logistics.png"></div>
              EU environmental monitoring system</div>

              <div class="col d-flex align-items-start"><div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-hilton.png"></div>
              5-star Hilton hotel, Visegrád</div>
              <div class="col d-flex align-items-start">
              <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-airport.png"></div>
              Airport development, Veszprém - Szentkirályszabadja</div>
              <div class="col d-flex align-items-start">
              <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-obuda.png"></div>
                Riverport apartment project, Budapest, district III.</div>

              <div class="col d-flex align-items-start">
                <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-kn.png"></div>
                Kühne &amp; Nagel Logisztikai Központ, Kecskemét</div>
              <div class="col d-flex align-items-start"><div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-mercedes.png"></div>
              Subdivision development, Kecskemét</div>
              <div class="col d-flex align-items-start">
              <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-biatorbagy.png"></div>
              Trade center and greenhouses, Biatorbágy</div>
              <div class="col d-flex align-items-start">
              <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-var.png"></div>
                Office building purchase and restructuring, Budapest, district I.</div>
			</div>
        </div>
		&nbsp;
</template>
<script>

export default{
   mounted () {
      document.querySelectorAll('.trns')[0].classList.add('show');   
   }
}

</script>