<template>
            <div class="row row-cols-1 row-cols-12 trns">
            <div class="col-md-6">
              <div class="row g-0 overflow-hidden flex-md-row mb-4 h-md-250 position-relative">
                  <div class="col d-flex align-items-start">
                    <div>
                      <h2 class="display-5 fw-bold">Kapcsolat</h2>
                      <h3 class="fw-bold">ECO-KVART Kft.</h3>
                      <p class="card-text mb-auto" style="margin:20px">
                          <strong>Székhely: </strong>
                          1123 Budapest, Kék Golyó utca 2. a ép. I. em. 2.
                      </p>
                      <p class="card-text mb-auto" style="margin:20px">
                          <strong>Nyitvatartás: </strong>
                          Hétfő-Péntek 9:00 - 17:00 óráig
                      </p>
                      <p class="card-text mb-auto" style="margin:20px">
                          <strong>Ügyvezetők</strong>
                          <ul>
                            <li>Tóth Ilona</li>
                            <li>Papp Ferenc</li>
                            <li>Papp Miklós</li>
                          </ul>
                      </p>
                      <p class="card-text mb-auto" style="margin:20px">
                          <strong>Telefonszám: </strong>
                          <a href="tel:003614876080">+361 487 6080</a>
                      </p>
                      <p class="card-text mb-auto" style="margin:20px">
                          <strong>E-mail: </strong>
                          <ul>
                            <li><a href="mailto:ecokvart@ecokvart.hu">ecokvart@ecokvart.hu</a></li>
                          </ul>
                      </p>
                      <p class="card-text mb-auto" style="margin:20px">
                          <strong>Cégnév: ECO-KVART Könyvviteli és Tanácsadó Kft.</strong>
                          
                      </p>
                      <p class="card-text mb-auto" style="margin:20px">
                          <strong>Cégjegyzékszám: </strong>
                          01-09-269427
                      </p>
                      <p class="card-text mb-auto" style="margin:20px">
                          <strong>Adószám: </strong>
                          10900530-2-43
                      </p>
                      <hr>
                      <h3 class="fw-bold">PRE-TAX Kft.</h3>
                      <p class="card-text mb-auto" style="margin:20px">
                          <strong>Székhely: </strong>
                          1123 Budapest, Kék Golyó utca 2. a ép. I. em. 2.
                      </p>
                      <p class="card-text mb-auto" style="margin:20px">
                          <strong>Nyitvatartás: </strong>
                          Hétfő-Péntek 9:00 - 17:00 óráig
                      </p>
                      <p class="card-text mb-auto" style="margin:20px">
                          <strong>Ügyvezetők</strong>
                          <ul>
                            <li>Kertész István</li>
                            <li>Bajzáth Andrea</li>
                            <li>Papp Ferenc</li>
                          </ul>
                      </p>
                      <p class="card-text mb-auto" style="margin:20px">
                          <strong>Telefonszám: </strong>
                          <a href="tel:003614876080">+361 487 6080</a>
                      </p>
                      <p class="card-text mb-auto" style="margin:20px">
                          <strong>E-mail: </strong>
                          <ul>
                            <li><a href="mailto:pretax@pretax.hu">pretax@pretax.hu</a></li>
                          </ul>
                      </p>
                      <p class="card-text mb-auto" style="margin:20px">
                          <strong>Cégnév: PRE-TAX Gazdasági Tanácsadó és Szolgáltató Korlátolt Felelősségű Társaság</strong>
                          
                      </p>
                      <p class="card-text mb-auto" style="margin:20px">
                          <strong>Cégjegyzékszám: </strong>
                          01-09-668341
                      </p>
                      <p class="card-text mb-auto" style="margin:20px">
                          <strong>Adószám: </strong>
                          12346866-2-43
                      </p>
                    </div>
                  </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                <img class="border shadow-sm" src="../assets/map.png" />
              </div>
            </div>
          </div>
</template>
<script>

export default{
   mounted () {
      document.querySelectorAll('.trns')[0].classList.add('show');   
   }
}

</script>
