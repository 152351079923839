import Navigation from '/src/components/Navigation.vue';
import HomePage from '/src/pages/Home.vue';
import Footer from '/src/components/Footer.vue';
import IntroPage from '/src/pages/Intro.vue';
import ServicesPage from '/src/pages/Services.vue';
import ReferencesPage from '/src/pages/References.vue';


export default [
    {
        name: 'home',
        path: '/',
        components: {
            navigation: Navigation,
            footer: Footer,
            default: HomePage
        },
    },
    {
        name: 'services',
        path: '/services',
        props: true,
        components: {
            navigation: Navigation,
            footer: Footer,
            default: ServicesPage
        },
    },
    {
        name: 'References',
        path: '/references',
        props: true,
        components: {
            navigation: Navigation,
            footer: Footer,
            default: ReferencesPage
        },
    },
    {
        name: 'intro',
        path: '/contact',
        props: true,
        components: {
            navigation: Navigation,
            footer: Footer,
            default: IntroPage
        },
    }
];
