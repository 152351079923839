<template>
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 g-4">
            <div class="col d-flex align-items-start">
            <div class="icon-square bg-light text-dark flex-shrink-0 me-3">
                <i class="fas fa-balance-scale mr-2"></i>
            </div>
            <div>
                <h4>Teljes körű könyvelés</h4>
                <p>Teljes körű könyvelés, adóbevallások és beszámolók készítése, bérszámfejtés és munkaügyek intézése, 
                    éves beszámolók, pénzügyi riportok, egyéb hatósági adatszolgáltatások készítése.</p>
            </div>
            </div>
            <div class="col d-flex align-items-start">
            <div class="icon-square bg-light text-dark flex-shrink-0 me-3">
                <i class="fas fa-check-square mr-2"></i>
            </div>
            <div>
                <h4>Cégvezetés</h4>
                <p>gazdasági adó és jogi tanácsadás – vezetői tanácsadás – személyre szóló vezetői tanácsadás (coaching),
                folyamatos együttműködés az üzletmenet során felvetődő kérdésekben.
                </p>
            </div>
            </div>
            <div class="col d-flex align-items-start">
            <div class="icon-square bg-light text-dark flex-shrink-0 me-3">
                <i class="fas fa-address-card mr-2"></i>
            </div>
            <div>
                <h4>Hatékonyság-növelés</h4>
                <p>Munkafolyamatba épített ellenőrzések kialakítása, cég diagnosztika, komplex controlling
                    rendszerek kialakítása.</p>
            </div>
            </div>
            <div class="col d-flex align-items-start">
            <div class="icon-square bg-light text-dark flex-shrink-0 me-3">
                <i class="fas fa-clock mr-2"></i>
            </div>
            <div>
                <h4>Projekt támogatás</h4>
                <p>A projektek jogi, számviteli, pénzügyi és adó megalapozása – due dilligence (adókockázati
                    felmérés – pályázatok, banki hitelkérelmek készítésében közreműködés.</p>
            </div>
            </div>
            <div class="col d-flex align-items-start">
            <div class="icon-square bg-light text-dark flex-shrink-0 me-3">
                <i class="fas fa-frown mr-2"></i>
            </div>
            <div>
                <h4>Krízis kezelés</h4>
                <p>Belső, illetve külső tényezők által előidézett rendkívüli helyzetekben nyújtott szakmai támogatás.</p>
            </div>
            </div>
            <div class="col d-flex align-items-start">
            <div class="icon-square bg-light text-dark flex-shrink-0 me-3">
                <i class="fas fa-thumbs-up mr-2"></i>
            </div>
            <div>
                <h4>Outsourcing</h4>
                <p>Az adminisztratív folyamatok részleges, illetve teljes körű kiszervezése jelentős hatékonyság
                    növekedést jelenthet.</p>
            </div>
        </div>
    </div>
</template>