<template>
            <div class="row row-cols-1 row-cols-12 trns">
            <h2 class="display-5 fw-bold">Cégünk fő tevékenységei</h2>
            <ol>
              <li><p><span class="fw-bold">Teljes körű könyvelés</span>, adóbevallások és beszámolók készítése, bérszámfejtés és munkaügyek intézése, éves beszámolók, 
                pénzügyi riportok, egyéb hatósági adatszolgáltatások készítése.</p>
                <p>Alap szolgáltatásunk az egyedi tranzakciók előzetes véleményezése, és szabályossági kontrollja.</p></li>
              <li><p><span class="fw-bold">Cégvezetést elősegítő szolgáltatások:</span>
              <ul>
                <li>Gazdasági adó és jogi tanácsadás</li>
                <li>Vezetői tanácsadás</li>
                <li>Személyre szóló vezetői tanácsadás (coaching)</li>
                <li>Vezetői ellenőrzési és riport rendszerek kidolgozása és működtetés</li>
                <li>Hazai és nemzetközi adótervezés</li>
                <li>Folyamat és ügyvitel szervezés</li>
                <li>Folyamatok szabályozása</li>
                <li>Vállalati tervezésben való közreműködés</li>
                <li>Kockázat elemzés</li>
                <li>Adóhatósági eljárásokban adószakértői képviselet, adóbevallások készítése, önellenőrzése. Külföldi ÁFA visszaigénylése</li>
                <li>Kifizetőhely nélküli társadalombiztosítási ügyintézés</li>
                <li>Egyéni adó és társadalombiztosítási ügyek intézése</li>
                <li>Folyamatos együttműködés az üzletmenet során felvetődő kérdésekben</li>
                <li>vezetői és munkavállalói érdekeltségi rendszerek kidolgozása, bérköltségek optimalizálása</li>
              </ul>
              </p></li>
              <li><p><span class="fw-bold">Belső ellenőrzési és hatékonyság növelési szolgáltatások:</span>
              <ul>
                <li>Munkafolyamatba épített ellenőrzések kialakítása, cég diagnosztika, komplex controlling rendszerek kialakítása</li>
                <li>Belső szabályzatok készítése</li>
                <li>Gazdasági visszaélések feltárása</li>
                <li>gazdasági tevékenység optimalizálása, belső ellenőrzés</li>
              </ul>
              </p></li>
              <li><p><span class="fw-bold">Projekt tervezési és megvalósítási szolgáltatások:</span>
              <ul>
                <li>A projektek jogi, számviteli, pénzügyi és adó megalapozása</li>
                <li>Due dilligence (adókockázati felmérés)</li>
                <li>Pályázatok, banki hitelkérelmek készítésében közreműködés</li>
                <li>Cégvásárlások, eladások átalakítások lebonyolítása</li>
                <li>Vállalat átalakítás</li>
                <li>Tranzakciók előkészítése és intézése</li>
              </ul>
              </p></li>
              <li><p><span class="fw-bold">Időszakos szakmai támogatás, krízis kezelés</span></p></li>
              <li><p><span class="fw-bold">Outsourcing szolgáltatások:</span> Az adminisztratív folyamatok részleges, illetve teljes körű kiszervezése 
              jelentős hatékonyságnövekedést jelenthet.
              <ul>
                <li>Teljes körű könyvelés, bérszámfejtés</li>
                <li>kimenő számlák készítése</li>
                <li>bejövő számlák érkeztetése, ellenőrzése, pénzügyi rendezésének előkészítése</li>
                <li>banki pénzforgalom intézése</li>
                <li>Üzleti levelezési, fordítási, titkársági szolgáltatások, üzleti tárgyalások előkészítése és lebonyolítása</li>
              </ul>
              </p></li>
            </ol>
        </div>
        <hr>
</template>
<script>

export default{
   mounted () {
      document.querySelectorAll('.trns')[0].classList.add('show');   
   }
}

</script>
