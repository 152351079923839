<template>
    <main role="main">
    <Carousel />
    <div class="container px-4">
        <Servicesshort v-if="lang === 'hu'" />
        <div  v-if="lang === 'hu'" @scroll="showText" class="row row-cols-1 trns">
            <h2 class="display-5 fw-bold">Filozófiánk</h2>
            <p>Közel 30 éves gyakorlatunk alapján meggyőződésünk, hogy nem csak a nagy létszámú vállalkozásoknak van szükségük megfelelő szakemberekre a cégvezetéshez, 
              szervezéshez, számviteli, pénzügyi, adózási és egyéb adminisztratív feladataik elvégzéséhez. Cégcsoportunk megvalósult, jó referenciákkal igazolható 
              gyakorlata, hogy a legkisebbtől a nagyobb középvállalkozásokig is tudunk a fenti területeken minőségi, az ügyfél igényeihez rugalmasan alkalmazkodó 
              szolgáltatásokat nyújtani megfelelő áron.</p>
            <p>A megrendelő adottságai alapján személyre szabottan kialakított szolgáltatás modulok révén az ügyfél pénzügyi és jogi biztonsága, az optimális megoldások 
              kiválasztása, a folyamatok vezetői és munkafolyamatokba épített ellenőrzése és így nagy fokú hatékonyság növekedés volt elérhető. A legtöbb vállalkozásnál 
              esély sincs arra, hogy gazdasági igazgatót, főkönyvelőt, munkaügyest, adószakértőt, jogászt, szervezőt, sőt, hogy egyáltalán fő foglalkozású alkalmazottat 
              tartsanak. De minden vállalkozás életében évente néhányszor erre szükség lenne, bár ezt a vállalkozó gyakran nem is veszi észre. Az egyre nehezebben
              áttekinthető és folyamatosan változó jogszabály dzsungelben gyakran még a rutin ügyleteken is változtatni kell, új tipusú tranzakció szakértői kontroll 
              nélküli létrehozása gyakran a vállalkozás létét is veszélyeztetheti!</p>
            <p>Mi készen állunk arra, hogy cégüknek ilyen multifunkcionális szakértői segítséget nyújtsunk! Pénz megtakarításban, adó optimalizálásban, biztonságban 
              legalább 3-4-szer többet tudunk Önnek nyújtani, mint amennyibe kerülünk! Szolgáltatásaink messze többet jelentenek, mint az az általános gyakorlat, hogy a 
              könyvelő egy nylon szatyorban nagy tömegű papírt kap: próbáljon meg kihozni belőle valamit, első sorban minél kevesebb adót! Mi a vállalkozást jól ismerve 
              segítünk a folyamatok előkészítésében, szervezésében, optimalizálásában. Nem a nulla adó az optimális adó mérték!</p>
            <p>A professzionálisan vezetett cégeknek is sok segítséget tudunk nyújtani. Egyrészt még a közepes vállalkozások felé is bizonyítottuk, hogy a hozzánk 
              kiszervezett adminisztratív folyamatok a megbízóink felé hatékonyabbak és biztonságosabbak. A biztonságot az jelenti, hogy folyamatosan van módunk a 
              szakirodalom és a jogesetek tanulmányozására, rendszeresen konzultálunk a pénzügyi kormányzat, illetve a szakma vezető tanácsadóival. Hosszú 
              vállalatvezetési tapasztalatainkat is gyakran tudjuk ügyfeleink érdekében kamatoztatni!</p>
        </div>
        <div v-if="lang === 'hu'"  class="row row-cols-1 row-cols-12 trns" style="background-color: #ddd;">
            <h2 class="display-5 fw-bold">Cégünk története</h2>
            <p>A Kék Golyó utcai irodában tevékenykedő cégek alapító tulajdonosai már a magyarországi rendszerváltást megelőzően 1973-tól, illetve 1983-tól együtt 
              tevékenykedtek. A jelenlegi cégcsoport jogelődjei 1990-től működtek. Így közel 30 év tapasztalataival rendelkezünk a folyamatos megújulás képessége 
              mellett.</p>
            <p>Már a kezdetek során is a cégcsoport akkori vállalkozásai célul tűzték ki, hogy a hagyományos könyvelői szolgáltatásokon túlmenően, bérszámfejtést 
              és a munkaügyek intézését, adó- és gazdasági tanácsadást is nyújtsanak ügyfeleiknek. A szolgáltatások továbbfejlesztése során fogalmazódott meg az a 
              stratégiai cél, amely szerint egy kis- és középvállalkozás nem igényel alacsonyabb szintű szolgáltatást, mint amit egy nagyobb cég tud csak 
              megfizetni. Ezért kezdetektől fogva igyekeztünk kevésbé fizetőképes ügyfeleinknek is olyan szolgáltatási struktúrát kialakítani, amely számukra is 
              megfizethető áron próbálja csökkenteni azt a hátrányt, hogy a kis cégek számára az egyes vállalatirányítási területekre nem gazdaságos folyamatosan 
              megfelelő szakapparátus foglalkoztatása.</p>
            <p>Munkatársaink korösszetétele optimális: a tehetséges fiatalok, a nagy gyakorlatú középkorúak és az idősebb korosztály harmonikus együttműködése 
              biztosított. Munkatársaink 70 %-a felsőfokú végzettségű. 2 adószakértőnk és 3 adótanácsadónk van, a regisztrált mérlegképes könyvelőink száma 13 fő, 
              közülük hárman (nemzetközi) IFRS jogosultságúak, 2 munkaügyi szakvégzettségű kolléga is segíti szolgáltatásaink teljesítését.</p>
            <p>2017-ben 192 vállalkozásnak nyújtottunk rendszeres szolgáltatást, ebből 168 vállalkozás teljeskörű könyvelését végeztük. Ezek együttes bevétele 
              29 milliárd kettőszázhuszonötmillió Ft volt, adózott eredményük 5,1 milliárd Ft, tárgyévi beruházási értékük 4,9 milliárd Ft. volt. Összesen 1024 
              fő bérszámfejtését végeztük.</p>
            <p>Minőségi szolgáltatást elősegíti, hogy problematikus esetekben ügyvéd, könyvvizsgáló, számítástechnikai rendszerszervező ad hoc segítségét is 
              igénybe tudjuk venni.</p>
            <p>Folyamatos – felsőfokú végzettségű kollégák által üzemeltetett – ügyfélszolgálattal rendelkezünk, amely a személyes és telefonos szolgáltatás 
              mellett elektronikusan is teljesíti ügyfeleink igényeit. Szolgáltatásainkat magyar és angol nyelven nyújtjuk, praktikus információkat jelenleg 
              körlevelek kiküldésével biztosítjuk.</p>
            <p>Jelentősek a külföldi tulajdonú (joint venture) cégek részére nyújtott szolgáltatásaink. Részükre folyamatosan angol és magyar nyelven párhuzamosan 
              nyújtunk szolgáltatásokat és tájékoztatást adunk az üzleti élet magyarországi sajátosságairól.</p>
            <p>Cégünk az elmúlt években nagy gyakorlatot szerzett a nemzetközi adózás területén. A világ 4 legnagyobb könyvvizsgáló cégével is működtünk együtt 
              (KPMG, ERNST &amp; YOUNG, DELOITTE, PRICE WATERHOUSE), de a Magyarországon jelentős BDO-val, Mazars-sal és a Szakértői Holdinggel is együtt dolgozunk.
              Kiemelt területünk az ÁFA törvény biztonságos alkalmazása, és az ÁFA visszaigénylések biztonságos lebonyolítása, valamint az ingatlan projektek 
              végrehajtása Sok ügyfelünknek már több, mint 20 éve szolgáltatunk, e vállalkozásoknál már joggal érezhetjük magunkat családtagnak.</p>
        </div>
        <div v-if="lang === 'en'"  class="row row-cols-1 row-cols-12 trns">
            <h2 class="display-5 fw-bold">History and services of the company</h2>
            <p>Our company was founded in 1998 continuation of activities of previous private entrepreneurs and companies. 
              Shareholders of the company have been working together for more than 30 years, previously as economic and 
              accounting managers of a state enterprise, and after the political restructuring they cooperated in providing 
              accounting and consultation services. Activity of the company constitutes approximately 55% from accounting and related services,
              40% from economic and tax consultation, project planning, preparing tenders and mediation activity. The rest of the income is related to data process 
              activity and business management services.</p>
            <p>Our company provides its services for 140-160 companies yearly, and for 130 clients we provide continuous accounting and consultation 
              services. So in the most sectors we have experience, especially in the fields of real estate investment, and utilisation, construction,
              hotel, medical and trade services. At different company sales and due diligences we cooperated all of the Big Four auditors.</p>
            <h3>Leader consultants</h3>
            <h5>Miklós Papp</h5>
            <ul>
              <li><i>Professional qualification</i>: chartered IFRS accountant, economist, tax expert.</li>
              <li><i>Training under process</i>: Auditor studies.</li>
              <li><i>Language</i>: English.</li>
              <li><i>Professional experience</i>:
                <p>
                 Worked as data processor and then as financial and economic assistant at the company. He has been working at the company 
                 since 1996 and studied all fields of office operation. In his thesis work he based theory of bookkeeping services for
                 small and medium-size enterprises. From 2003 he is the leader of the company, managing operative work at the company 
                 and exercises the Employer’s rights.
                </p>
              </li>
              <li><i>Main area of expertise</i>:
                <ul>
                  <li>System development and management</li>
                  <li>Tax consultation</li>
                  <li>Working out company information system and operation</li>
                  <li>Computer system development and operation</li>
                  <li>Project planning, preparing tenders and loan enquiry</li>
                </ul>
              </li>
            </ul>
            <h5>István Kertész</h5>
            <ul>
              <li><i>Professional qualification</i>: investment assistant, car operation manager, chartered economict
              (company economic manager), chartered expert economist (company panner and analyser), chartered accountant, 
              Oxford CMS degree, tax consultant, tax expert.</li>
              <li><i>Language</i>: English.</li>
              <li><i>Professional experience</i>:
                <p>
                 Worked for 5 years as economic assistant, for 2 years as research manager, for 13 years economic manager at a state 
                 enterprise then between 1991 and 2004 member of the Board of Directors of this enterprise. From 1994 he has been working 
                 at his own consultant companies. For 6 years he was dealing with macroeconomical researches, he made more publications in 
                 this subject. At university studies acting as practice leader, and also acting as theme leader at thesis works of college 
                 students.
                </p>
              </li>
              <li><i>Main area of expertise</i>:
                <ul>
                  <li>Economy of research and development</li>
                  <li>Economic and tax consultation</li>
                  <li>Project planning and preparing tenders</li>
                  <li>Planning and managing external and internal cooperation</li>
                  <li>Checking legal and tax-legal base of economic activity</li>
                  <li>Finding out alternatives for measures and their tax effects</li>
                  <li>Finding out problems and their solutions systematically</li>
                  <li>Coaching (complex manager consultation)</li>
                  <li>Mediation,</li>
                  <li>Business correspondance</li>
                  <li>Company analysis</li>
                </ul>
              </li>
            </ul>
            <p>Our companies have 16 employees, among which 12 have higher education degree.</p>
        </div>
        <References_txt v-if="lang === 'hu'" />
        <References_txt_en v-if="lang === 'en'" />
        <div  v-if="lang === 'hu'" class="row row-cols-1 row-cols-12 trns" style="background-color: #ddd;">
            <h2 class="display-5 fw-bold">Értékeink</h2>
              <p>Feddhetetlenség.</p>
              <p>Felelősség.</p>
              <p>Szakértelem és széles körű szakmai gyakorlat.</p>
              <p>Folytonosság.</p>
              <p>Az üzleti élettel összefüggő joggyakorlat naprakész nyomon kísérése.</p>
        </div>
        <Services_txt v-if="lang === 'hu'"  />

      </div> <!-- /container -->

    </main>
</template>
<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import Carousel from "/src/components/Carousel.vue";
import Servicesshort from "/src/components/Servicesshort.vue";
import References_txt from '/src/components/References_txt.vue';
import References_txt_en from '/src/components/References_txt_en.vue';
import Services_txt from '/src/components/Services_txt.vue';

export default{
  components:{
    Carousel,
    Servicesshort,
    References_txt,
    References_txt_en,
    Services_txt
  },
  props: ['lang'],
  setup(){
    const route = useRoute();
    const isHome = computed(() => route.name === 'home');

    return{
      isHome,
    };
  },
  mounted () {

    window.addEventListener('scroll', function() {
      var trnsTexts = document.querySelectorAll('.trns');
      
      trnsTexts.forEach(box => {
        if(box.getBoundingClientRect().top < window.innerHeight - 100){
            box.classList.add('show');
        }     
      })
    });
  }
}

</script>