<template>
        <div class="row row-cols-1 row-cols-12 trns">
            <h2 class="display-5 fw-bold">Referenciák *</h2>
            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
              <div class="col d-flex align-items-start">
                <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-auchan.png"></div>
                AUCHAN Logisztikai Központ, Üllő</div>
              <div class="col d-flex align-items-start"><div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-obuda.png"></div>
              Sportkomplexum beruházás, Budapest III. kerület</div>
              <div class="col d-flex align-items-start">
              <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-budakalasz.png"></div>
                Gyógynövény feldolgozó üzem, Budakalász</div>
              <div class="col d-flex align-items-start">
              <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-renault.png"></div>
              Renault-Nissan raktár és logisztikai beruházás Győr</div>

              <div class="col d-flex align-items-start">
                <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-continental.png"></div>
                Continental Logisztikai Központ Mosonmagyaróvár</div>
              <div class="col d-flex align-items-start"><div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-ikarus.png"></div>
              IKARUS ipari park vásárlás, Budapest</div>
              <div class="col d-flex align-items-start">
              <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-dhl.png"></div>
                DHL Logisztikai Központ beruházás (2006 az év ingatlanfejlesztése díjas)</div>
              <div class="col d-flex align-items-start">
              <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-nolato.png"></div>
              Nolato Ipari park vásárlás és értékesítés, Jászberény</div>

              <div class="col d-flex align-items-start">
                <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-logistics.png"></div>
                Logisztikai Központ vásárlás, Budapest XVII.</div>
              <div class="col d-flex align-items-start"><div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-logistics.png"></div>
              Logisztikai Központ vásárlás, Nyíregyháza</div>
              <div class="col d-flex align-items-start">
              <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-renault.png"></div>
              Renault-Dacia Logisztikai Központ beruházás, Pitesti Románia</div>
              <div class="col d-flex align-items-start">
              <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-logistics.png"></div>
                Logisztikai központ beruházás, Pécel</div>

              <div class="col d-flex align-items-start">
                <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-monitoring.png"></div>
                Uniós Környezetvédelmi Monitoring rendszer</div>
              <div class="col d-flex align-items-start"><div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-hilton.png"></div>
              5 csillagos HILTON szálloda, Visegrád</div>
              <div class="col d-flex align-items-start">
              <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-obuda.png"></div>
              Riverport üdülőpark beruházás, Budapest III.</div>
              <div class="col d-flex align-items-start">
              <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-mercedes.png"></div>
                Mercedes Lakópark fejlesztés, Kecskemét</div>

              <div class="col d-flex align-items-start">
                <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-kn.png"></div>
                Kühne &amp; Nagel Logisztikai Központ, Kecskemét</div>
              <div class="col d-flex align-items-start"><div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-biatorbagy.png"></div>
              Kereskedelmi központ és növényházak, Biatorbágy</div>
              <div class="col d-flex align-items-start">
              <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-var.png"></div>
              Iroda vásárlás, projekt értékesítés, Budapest I.</div>
              <div class="col d-flex align-items-start">
              <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-monitoring.png"></div>
                28 vállalkozás átalakítása (kiválás, beolvadás) szervezeti és adóoptimalizálással</div>

              <div class="col d-flex align-items-start">
                <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-monitoring.png"></div>
                Nonprofit szervezetek közhasznú szervezetté alakítás</div>
              <div class="col d-flex align-items-start"><div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-hotel.png"></div>
              Szállodai ügyviteli rendszerek kialakítása</div>
              <div class="col d-flex align-items-start">
              <div class="icon-square bg-light text-dark flex-shrink-0 me-3"><img src="../assets/logo-monitoring.png"></div>
              150 cég teljes körű, illetve új projektjeinek kialakítása</div>
              <div class="col d-flex align-items-start">
                </div>
            </div>
            <p>Továbbá
            <ul>
              <li>Brit, holland, lengyel, cseh, román, szerb, francia, német, török, izraeli, jordán, szíriai, kanadai, orosz, ukrán, francia, német, 
                amerikai, argentin cégek részére nemzetközi adótervezés</li>
              <li>Mintegy 200 eredményes pályázatban, illetve hitelkérelemben való közreműködés</li>
              <li>Mintegy 500 adó és más hatósági ellenőrzésen közreműködés több mint 3500 millió Ft. ÁFA visszaigényléséhez képviselet</li>
              <li>Vállalatirányítási rendszerek adaptálása (SAP, Navision, Dimension)</li>
            </ul></p>
            <p class="small">* A megrendelőnk általában a világcég részére beszállító magyar vállalkozás</p>
        </div>
</template>
<script>

export default{
   mounted () {
      document.querySelectorAll('.trns')[0].classList.add('show');   
   }
}

</script>
