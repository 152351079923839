<template>
    <main role="main">
      <div class="container px-4">
      <Contact_txt v-if="lang === 'hu'"  />
      <Contact_txt_en v-if="lang === 'en'"  />
       </div>
    </main>
</template>
<style scoped>
  main {
  padding-top: 3.5rem;
}
</style>
<script>
import Contact_txt from '/src/components/Contact.vue';
import Contact_txt_en from '/src/components/Contact_en.vue';

export default {
  components:  {
    Contact_txt,
    Contact_txt_en
  },
  props: ['lang'],
}
</script>