<template>
      <div id="precoCarousel" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#precoCarousel" 
            data-bs-slide-to="0" class="active" aria-current="true" aria-label="Első oldal">
          </button>
          <button type="button" data-bs-target="#precoCarousel" 
            data-bs-slide-to="1" aria-label="Második oldal">
          </button>
          <button type="button" data-bs-target="#precoCarousel" 
            data-bs-slide-to="2" aria-label="Harmadik oldal">
          </button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="../assets/splash1_small.png" />
            <div class="container">
              <div class="carousel-caption text-start">
                <h1>Természetesen. Tudunk. Segíteni.</h1>
                <!-- p><a class="btn btn-lg btn-primary" href="#">Tudjon meg többet</a></p -->
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <img src="../assets/splash2_small.png" />
            <div class="container">
              <div class="carousel-caption text-start">
                <span>H-P: 09:00-17:00</span>
                <h1>Foglaljon helyet nálunk!</h1>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <img src="../assets/splash3_small.png" />

            <div class="container">
              <div class="carousel-caption text-start">
                <h1>Akár dedikált VPN kapcsolattal.</h1>
              </div>
            </div>
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#precoCarousel" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#precoCarousel" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
</template>