<template>
            <div class="row row-cols-1 row-cols-12 trns">
            <div class="col-md-6">
              <div class="row g-0 overflow-hidden flex-md-row mb-4 h-md-250 position-relative">
                  <div class="col d-flex align-items-start">
                    <div>
                      <h2 class="display-5 fw-bold">Contact</h2>
                      <h3 class="fw-bold">ECO-KVART Kft.</h3>
                      <p class="card-text mb-auto" style="margin:20px">
                          <strong>Address: </strong>
                          1123 Budapest, Kék Golyó utca 2/a.
                      </p>
                      <p class="card-text mb-auto" style="margin:20px">
                          <strong>Opening hours: </strong>
                          Monday to Friday: 9:00 - 17:00
                      </p>
                      <p class="card-text mb-auto" style="margin:20px">
                          <strong>General Managers</strong>
                          <ul>
                            <li>Ilona Tóth</li>
                            <li>Ferenc Papp</li>
                            <li>Miklós Papp</li>
                          </ul>
                      </p>
                      <p class="card-text mb-auto" style="margin:20px">
                          <strong>Phone: </strong>
                          <a href="tel:003614876080">+361 487 6080</a>
                      </p>
                      <p class="card-text mb-auto" style="margin:20px">
                          <strong>E-mail: </strong>
                          <ul>
                            <li><a href="mailto:ecokvart@ecokvart.hu">ecokvart@ecokvart.hu</a></li>
                          </ul>
                      </p>
                      <p class="card-text mb-auto" style="margin:20px">
                          <strong>Tax number: </strong>
                          10900530-2-43
                      </p>
                      <hr>
                      <h3 class="fw-bold">PRE-TAX Kft.</h3>
                      <p class="card-text mb-auto" style="margin:20px">
                          <strong>Address: </strong>
                          1123 Budapest, Kék Golyó utca 2/a.
                      </p>
                      <p class="card-text mb-auto" style="margin:20px">
                          <strong>Opening hours: </strong>
                          Monday to Friday: 9:00 - 17:00
                      </p>
                      <p class="card-text mb-auto" style="margin:20px">
                          <strong>General Managers</strong>
                          <ul>
                            <li>István Kertész</li>
                            <li>Andrea Bajzáth</li>
                            <li>Ferenc Papp</li>
                          </ul>
                      </p>
                      <p class="card-text mb-auto" style="margin:20px">
                          <strong>Phone: </strong>
                          <a href="tel:003614876080">+361 487 6080</a>
                      </p>
                      <p class="card-text mb-auto" style="margin:20px">
                          <strong>E-mail: </strong>
                          <ul>
                            <li><a href="mailto:pretax@pretax.hu">pretax@pretax.hu</a></li>
                          </ul>
                      </p>
                      <p class="card-text mb-auto" style="margin:20px">
                          <strong>Tax number: </strong>
                          12346866-2-43
                      </p>
                    </div>
                  </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                <img class="border shadow-sm" src="../assets/map.png" />
              </div>
            </div>
          </div>
</template>
<script>

export default{
   mounted () {
      document.querySelectorAll('.trns')[0].classList.add('show');   
   }
}

</script>