<template>
    <main role="main">
    <div class="container px-4">
        <References_txt v-if="lang === 'hu'"/>
        <References_txt_en v-if="lang === 'en'"/>
    </div> <!-- /container -->
    </main>
</template>
<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import References_txt from '/src/components/References_txt.vue';
import References_txt_en from '/src/components/References_txt_en.vue';
export default{
  components:{
    References_txt,
    References_txt_en
  },
  props: ['lang'],
  setup(){
	const route = useRoute();
	const isHome = computed(() => route.name === 'home');

    return{
      isHome,
    };
  }
}

</script>
<style scoped>
  main {
  padding-top: 3.5rem;
}
</style>