<template>
  <router-view name="navigation" @change="lang_selected">
  </router-view>
  <router-view :lang="selectedLang"/>
  <router-view name="footer">
  </router-view>
</template>

<script>

export default {
  data() {
    return { selectedLang: 'hu' }
  },

  methods: { 
    lang_selected(myval) {
      if(typeof myval === "string") {
          this.selectedLang = myval;
//          console.log(myval);
      }
    }
  },
  setup() {
    },
    onMounted() {
      
    }
}
</script>
