import { createApp } from 'vue'
import App from './App.vue'

import './assets/main.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import { router } from './common/router.js';
import routes from './routes.js';
routes.forEach(route => router.addRoute(route));

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

createApp(App).use(router).mount('#app')
