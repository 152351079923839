<template>
    <footer class="footer mt-auto py-3 bg-dark">
        <div class="row">
          <div class="col-md-6 text-end">
            <h6>&copy; Pretax Kft, 2021.</h6>
            <ul class="list-unstyled text-small">
                <li>Cím: 1123 Budapest, Kék Golyó utca 2/a</li>
                <li>Telefonszám: <a href="tel:003614876080">+361 487 6080</a></li>
                <li>Email: <a href="mailto:pretax@pretax.hu">pretax@pretax.hu</a></li>
                <li>Cégjegyzékszám: 01-09-668341</li>
                <li>Adószám: 12346866-2-43</li>
            </ul>
          </div>
          <div class="col-md-6">
            <h6>&copy; Eco-kvart Kft, 2021.</h6>
            <ul class="list-unstyled text-small">
                <li>Cím: 1123 Budapest, Kék Golyó utca 2/a</li>
                <li>Telefonszám: <a href="tel:003614876080">+361 487 6080</a></li>
                <li>Email: <a href="mailto:ecokvart@ecokvart.hu">ecokvart@ecokvart.hu</a></li>
                <li>Cégjegyzékszám: 01-09-269427</li>
                <li>Adószám: 10900530-2-43</li>
            </ul>
          </div>
        </div>
        <!-- div class="row">
            <p class="text-center">Weboldalunk használatával ön egyben beleegyezik a cookie-k használatába.</p>
        </div -->
    </footer>

</template>
<script>
export default{

  setup(){

  }
}
</script>